import logo from 'assets/images/home1/logo.png'
import { ReactComponent as DiscordIcon } from 'assets/images/svg/discord.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/svg/twitter.svg'
import { ReactComponent as VectorTop } from 'assets/images/home1/vector-top.svg'
import { ReactComponent as VectorRight } from 'assets/images/home1/vector-right.svg'
import { ReactComponent as VectorLeft } from 'assets/images/home1/vector-left.svg'
import lama from 'assets/images/home1/llamas/1.png'
import lama2 from 'assets/images/home1/llamas/2.png'
import lama3 from 'assets/images/home1/llamas/3.png'
import lama4 from 'assets/images/home1/llamas/4.png'
import lama5 from 'assets/images/home1/llamas/5.png'
import lama6 from 'assets/images/home1/llamas/6.png'
import frog from 'assets/images/home1/frog.png'
import birdie from 'assets/images/home1/birdie.png'
import toy from 'assets/images/home1/toy.png'
import lamaStep6 from 'assets/images/home1/lama-step6.png'
import hedgehog from 'assets/images/home1/hedgehog.png'
import snail from 'assets/images/home1/snail.png'
import faqLlama from 'assets/images/home1/faq-llama.png'

import colection1 from 'assets/images/home1/collection/1.png'
import colection2 from 'assets/images/home1/collection/2.png'
import colection3 from 'assets/images/home1/collection/3.png'
import colection4 from 'assets/images/home1/collection/4.png'
import colection5 from 'assets/images/home1/collection/5.png'
import colection6 from 'assets/images/home1/collection/6.png'
import colection7 from 'assets/images/home1/collection/7.png'
import colection8 from 'assets/images/home1/collection/8.png'
import colection9 from 'assets/images/home1/collection/9.png'
import colection10 from 'assets/images/home1/collection/10.png'

import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import Team from './Team'
import Faq from './Faq'

enum NavList {
	home = 'home',
	art = 'art',
	toy = 'toy',
	team = 'team',
	faq = 'faq',
}

const rightCollection = [
	colection1,
	colection2,
	colection3,
	colection4,
	colection5,
	colection6,
	colection7,
	colection8,
	colection9,
	colection10,
]

const leftCollection = [
	colection1,
	colection2,
	colection4,
	colection3,
	colection8,
	colection5,
	colection9,
	colection6,
	colection8,
	colection7,
	colection10,
]

const images = [lama, lama2, lama3, lama4, lama5, lama6]

export default function Home1Component() {
	const [activeTab, setActiveTab] = useState<NavList>(NavList.home)
	const [currentImage, setCurrentImage] = useState(0)
	const [step1, setStep1] = useState(true)
	const [step2, setStep2] = useState(false)
	const [step3, setStep3] = useState(false)
	const [step4, setStep4] = useState(false)
	const [step5, setStep5] = useState(false)
	const [step6, setStep6] = useState(false)
	const [step7, setStep7] = useState(false)
	const [step8, setStep8] = useState(false)
	const [step9, setStep9] = useState(false)

	const changeImage = useCallback(() => {
		let nextImage = currentImage + 1
		if (nextImage >= images.length) {
			nextImage = 0
		}
		setCurrentImage(nextImage)
	}, [currentImage])

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null
		if (step1) {
			interval = setInterval(() => {
				changeImage()
			}, 3000)
		} else {
			setCurrentImage(0)
			return () => clearInterval(interval as NodeJS.Timeout)
		}
		return () => clearInterval(interval as NodeJS.Timeout)
	}, [changeImage, step1])

	useEffect(() => {
		const mainPage = document.querySelector('.home1-page ')
		if (step2) {
			mainPage?.classList.add('step2'), mainPage?.classList.remove('step3')
		} else if (step3 || step4 || step5) {
			mainPage?.classList.add('step3'), mainPage?.classList.remove('step6')
		} else if (step6 || step7) {
			mainPage?.classList.add('step6'), mainPage?.classList.remove('step8')
		} else if (step8) {
			mainPage?.classList.add('step8'), mainPage?.classList.remove('step9')
		} else if (step9) {
			mainPage?.classList.add('step9')
		} else {
			mainPage?.classList.remove('step2')
			mainPage?.classList.remove('step3')
			mainPage?.classList.remove('step6')
			mainPage?.classList.remove('step8')
			mainPage?.classList.remove('step9')
		}
	}, [step2, step3, step4, step5, step6, step7, step8, step9])
	return (
		<>
			<VectorTop
				className={clsx('vector-top', {
					step2: step2,
					step3: step3 || step4 || step5,
					step6: step6 || step7,
					step8: step8,
					step9: step9,
				})}
			/>
			<VectorRight
				className={clsx('vector-right', {
					step2: step2,
					step3: step3 || step4 || step5,
					step6: step6 || step7,
					step8: step8,
					step9: step9,
				})}
			/>
			<VectorLeft
				className={clsx('vector-left', {
					step2: step2,
					step3: step3 || step4 || step5,
					step6: step6 || step7,
					step8: step8,
					step9: step9,
				})}
			/>
			<div className='main-container'>
				<div>
					<img className='logo-img' src={logo} alt='' />
					<nav className='nav-box'>
						<ul className='nav-box-list'>
							{Object.values(NavList).map((item) => (
								<li
									key={item}
									className={clsx({
										active: activeTab === item,
									})}
									onClick={() => {
										setActiveTab(item)
									}}
								>
									{item}
								</li>
							))}
						</ul>
					</nav>
					<div className='social-box'>
						<TwitterIcon /> <DiscordIcon />
					</div>
				</div>
				<div className='content-box'>
					{/* // step1 */}
					<div
						className={clsx('text-funky-llamas', {
							step1: step1,
						})}
					>
						<div className='text-funky'>funky</div>
						<div className='text-llamas'>
							<span>l</span>
							<span>l</span>
							<span>a</span>
							<span>m</span>
							<span>a</span>
							<span>s</span>
						</div>
					</div>
					<div
						className={clsx('llamas-img-box', {
							step2: step2,
							step3: step3 || step4 || step5 || step6 || step7 || step8 || step9,
						})}
					>
						{images.map((image, index) => (
							<img
								key={index}
								src={image}
								className='llamas-img '
								style={{
									opacity: currentImage === index ? 1 : 0,
									position: currentImage === index ? 'relative' : 'absolute',
								}}
							/>
						))}
					</div>

					<div
						className={clsx('relative-box', {
							step2: step2,
							step3: step3,
							step4: step4,
							step5: step5 || step6,
							step8: step8,
							step9: step9,
						})}
					>
						{/* // step2 welcome text*/}
						<div
							className={clsx('wel-text', {
								step2: step2,
								step3: step3 || step4 || step5 || step6 || step7 || step8 || step9,
							})}
						>
							WEL
						</div>
						<div
							className={clsx('come-text', {
								step2: step2,
								step3: step3 || step4 || step5 || step6 || step7 || step8 || step9,
							})}
						>
							COME!
						</div>
						<div
							className={clsx('welcome-description', {
								step2: step2,
								step3: step3 || step4 || step5 || step6 || step7 || step8 || step9,
							})}
						>
							Step into the groove with Funky Llamas: 3,333 unique, trend-setting <br /> NFTs on Solana where retro
							charm meets digital innovation. <br />
							Funky heard is the vibe.
						</div>
						{/* // step3 collection text*/}
						<div
							className={clsx('collect-text', {
								step3: step3,
								step4: step4,
								step5: step5 || step6 || step7 || step8 || step9,
							})}
						>
							<div>
								collect
								<img
									src={frog}
									alt=''
									className={clsx('frog-img', {
										step4: step4 || step5,
									})}
								/>
								<img
									src={birdie}
									alt=''
									className={clsx('birdie-img', {
										step4: step4 || step5,
									})}
								/>
							</div>
						</div>
						<div
							className={clsx('ion-text', {
								step3: step3,
								step4: step4,
								step5: step5 || step6 || step7 || step8 || step9,
							})}
						>
							ion
						</div>
						{/* <div
							className={clsx('collection-description', {
								step3: step3,
								step4: step4 || step5 || step6 || step7 || step8 || step9,
							})}
						>
							Our collection is a tribute to the vibrant funk and soul of the 80s and 90s, reimagined for the digital
							era.
							<br />
							Every NFT in our collection is a masterpiece, blending retro charm with cutting-edge digital artistry.{' '}
							<br />
							Prepare to be amazed by a spectrum of colors, patterns, and stories, all encapsulated in our uniquely
							Funky Llamas.
						</div> */}
						{/* // step4/5 collection text with img*/}
						<div
							className={clsx('right-collection', {
								step4: step4,
								step5: step5,
								'step5 step6': step6 || step7 || step8 || step9,
							})}
						>
							{rightCollection.map((item, index) => (
								<img key={index} src={item} alt='' />
							))}
						</div>
						<div
							className={clsx('left-collection', {
								step4: step4,
								step5: step5,
								'step5 step6': step6 || step7 || step8 || step9,
							})}
						>
							{leftCollection.map((item, index) => (
								<img key={index} src={item} alt='' />
							))}
						</div>
						{/* // step6/7 toy with text*/}
						<img
							src={toy}
							alt=''
							className={clsx('toy-img', {
								step6: step6,
								step7: step7,
								step8: step8 || step9,
							})}
						/>
						<div
							className={clsx('llama-text', {
								step7: step7,
								step8: step8 || step9,
							})}
						>
							llama
						</div>
						<div
							className={clsx('gotchi-text', {
								step7: step7,
								step8: step8 || step9,
							})}
						>
							gotchi
						</div>
						<div
							className={clsx('llama-description', {
								step7: step7,
							})}
						>
							<span>Introducing the Llamagotchi, where the digital meets the delightfully llama!</span>
							Adopt your own virtual llama buddy in this NFT-powered universe, nurturing it <br />
							through the groovy stages of life, from funky fleece days to legendary llama <br />
							vibes - it&lsquo;s not just a pet, it&lsquo;s a llama lifestyle experience!
							<button className='discover-button'>
								<span>DISCOVER</span>
							</button>
						</div>
						<img
							src={lamaStep6}
							alt=''
							className={clsx('lamaStep6-img', {
								step7: step7,
							})}
						/>
						{/* // step8 team*/}
						<Team step8={step8} step9={step9} />
						<img
							src={hedgehog}
							alt=''
							className={clsx('hedgehog-img', {
								step8: step8,
							})}
						/>
						<img
							src={frog}
							alt=''
							className={clsx('frog-img-step8', {
								step8: step8,
							})}
						/>
						{/* // step8 Faq*/}
						<Faq step9={step9} />
						<img src={snail} alt='' className={clsx('snail-img', { step9: step9 })} />
					</div>
					<img src={faqLlama} alt='' className={clsx('faqLlama-img', { step9: step9 })} />
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'start',
					gap: 10,
					position: 'absolute',
					left: 20,
					top: 150,
					zIndex: 1000,
				}}
			>
				<button
					onClick={() => {
						setStep1(true),
							setStep2(false),
							setStep3(false),
							setStep4(false),
							setStep5(false),
							setStep6(false),
							setStep7(false),
							setStep8(false),
							setStep9(false)
					}}
				>
					step 1
				</button>
				<button
					onClick={() => {
						setStep1(false),
							setStep2(true),
							setStep3(false),
							setStep4(false),
							setStep5(false),
							setStep6(false),
							setStep7(false),
							setStep8(false),
							setStep9(false)
					}}
				>
					step 2
				</button>
				<button
					onClick={() => {
						setStep1(false),
							setStep2(false),
							setStep3(true),
							setStep4(false),
							setStep5(false),
							setStep6(false),
							setStep7(false),
							setStep8(false),
							setStep9(false)
					}}
				>
					step 3
				</button>
				<button
					onClick={() => {
						setStep1(false),
							setStep2(false),
							setStep3(false),
							setStep4(true),
							setStep5(false),
							setStep6(false),
							setStep7(false),
							setStep8(false),
							setStep9(false)
					}}
				>
					step 4
				</button>
				<button
					onClick={() => {
						setStep1(false),
							setStep2(false),
							setStep3(false),
							setStep4(false),
							setStep5(true),
							setStep6(false),
							setStep7(false),
							setStep8(false),
							setStep9(false)
					}}
				>
					step 5
				</button>
				<button
					onClick={() => {
						setStep1(false),
							setStep2(false),
							setStep3(false),
							setStep4(false),
							setStep5(false),
							setStep6(true),
							setStep7(false),
							setStep8(false),
							setStep9(false)
					}}
				>
					step 6
				</button>
				<button
					onClick={() => {
						setStep1(false),
							setStep2(false),
							setStep3(false),
							setStep4(false),
							setStep5(false),
							setStep6(false),
							setStep7(true),
							setStep8(false),
							setStep9(false)
					}}
				>
					step 7
				</button>
				<button
					onClick={() => {
						setStep1(false),
							setStep2(false),
							setStep3(false),
							setStep4(false),
							setStep5(false),
							setStep6(false),
							setStep7(false),
							setStep8(true),
							setStep9(false)
					}}
				>
					step 8
				</button>
				<button
					onClick={() => {
						setStep1(false),
							setStep2(false),
							setStep3(false),
							setStep4(false),
							setStep5(false),
							setStep6(false),
							setStep7(false),
							setStep8(false),
							setStep9(true)
					}}
				>
					step 9
				</button>
			</div>
		</>
	)
}
