import { ReactComponent as LoadingIcon } from 'assets/images/home/main-loading.svg'
import { ReactComponent as LogoIcon } from 'assets/images/home/logo.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/svg/twitter.svg'
import { ReactComponent as ButtonArrImg } from 'assets/images/home/button-arr.svg'

import FaceLlamaImg from 'assets/images/home/face_llama.png'
import shadowImg from 'assets/images/home/shadow.png'

import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { CSSTransition } from 'react-transition-group'

import { Link } from 'react-router-dom'
import MusicPlayer from './MusicPlayer'
import ToyRivComponent from './ToyRivComponent'
import LlamasRivComponent from './LlamasRivComponent'
import ArmBallRivComponent from './ArmBallRivComponent'

export default function HomeComponent({ isFirstLoaded }: { isFirstLoaded: boolean }) {
	const toyRef = useRef(null)
	const textRef = useRef(null)
	const moreInfoRef = useRef<HTMLButtonElement>(null)
	const logoRef = useRef<SVGSVGElement>(null)

	const [showLogoButton, setShowLogoButton] = useState(false)
	const [startOnToyScreen, setStartOnToyScreen] = useState(false)
	const [endTextAnimation, setEndTextAnimation] = useState(false)

	useEffect(() => {
		const lamaElement = document.querySelector('.lama-icon')
		const mainBox = document.querySelector('.home-page')
		if (isFirstLoaded) {
			lamaElement?.classList.remove('scale-lama-start')
			return
		}

		const styleLama = setTimeout(() => {
			lamaElement?.classList.remove('scale-lama-start')
			lamaElement?.classList.add('scale-lama-end')
			mainBox?.classList.add('main-background')
		}, 1700)
		return () => {
			clearTimeout(styleLama)
		}
	}, [isFirstLoaded])

	useEffect(() => {
		if (isFirstLoaded) {
			setShowLogoButton(true)
		}
		const styleLogo = setTimeout(() => {
			setShowLogoButton(true)
		}, 4000)
		return () => {
			clearTimeout(styleLogo)
		}
	}, [isFirstLoaded])

	useEffect(() => {
		if (endTextAnimation || isFirstLoaded) {
			const firstScales = setTimeout(() => {
				moreInfoRef?.current?.animate(
					[{ transform: 'scale(1)' }, { transform: 'scale(1.15)' }, { transform: 'scale(1)' }],
					{
						duration: 250,
						iterations: 1,
					},
				)
			}, 1000)

			const moreInfoButtonScale = setInterval(() => {
				moreInfoRef?.current?.animate(
					[{ transform: 'scale(1)' }, { transform: 'scale(1.15)' }, { transform: 'scale(1)' }],
					{
						duration: 250,
						iterations: 1,
					},
				)
			}, 5000)

			return () => {
				clearInterval(moreInfoButtonScale)
				clearTimeout(firstScales)
			}
		}
	}, [endTextAnimation, isFirstLoaded])

	useEffect(() => {
		if (endTextAnimation || isFirstLoaded) {
			const logoScale = setInterval(() => {
				logoRef?.current?.animate(
					[{ transform: 'scale(1)' }, { transform: 'scale(1.15)' }, { transform: 'scale(1)' }],
					{
						duration: 250,
						iterations: 1,
					},
				)
			}, 4800)

			return () => {
				clearInterval(logoScale)
			}
		}
	}, [endTextAnimation, isFirstLoaded])

	return (
		<>
			<LogoIcon
				ref={logoRef}
				className={clsx('logo-icon', {
					'logo-active': showLogoButton,
					'logo-active-loaded': isFirstLoaded,
				})}
			/>

			<div
				className={clsx('social-box', {
					'social-box-active': showLogoButton,
					'social-box-active-loaded': isFirstLoaded,
				})}
			>
				<Link to='https://twitter.com/funkyllamasnft' target='_blank'>
					<button className='social-button'>
						<TwitterIcon />
					</button>
				</Link>
			</div>
			{!startOnToyScreen && !isFirstLoaded && (
				<div className='loading-box'>
					<img src={FaceLlamaImg} alt='' className='lama-icon scale-lama-start' />
					<LoadingIcon className='loading-icon' />
				</div>
			)}

			<CSSTransition
				in={showLogoButton || isFirstLoaded}
				nodeRef={toyRef}
				timeout={800}
				classNames='toy-icon-box'
				unmountOnExit
				onEntered={() => {
					setStartOnToyScreen(true)
				}}
			>
				<span
					ref={toyRef}
					className={clsx({
						'toy-icon-box-enter-done': isFirstLoaded,
					})}
				>
					<ToyRivComponent />
				</span>
			</CSSTransition>

			<img
				src={shadowImg}
				alt=''
				className={clsx('shadow-img', {
					'shadow-img-active': showLogoButton || isFirstLoaded,
					'active-animation': startOnToyScreen || isFirstLoaded,
				})}
			/>

			<div
				className={clsx('lama-img-box', {
					'lama-img-active': showLogoButton,
					'lama-img-active-loaded': isFirstLoaded,
				})}
			>
				<LlamasRivComponent isFirstLoaded={isFirstLoaded} />
			</div>

			<div
				className={clsx('hand-ball-img', {
					'hand-ball-img-active': showLogoButton,
					'hand-ball-img-loaded': isFirstLoaded,
				})}
			>
				<ArmBallRivComponent />
			</div>

			<CSSTransition
				in={showLogoButton || isFirstLoaded}
				nodeRef={textRef}
				timeout={800}
				classNames='text-box-active'
				unmountOnExit
				onEntered={() => {
					setEndTextAnimation(true)
				}}
			>
				<div
					ref={textRef}
					className={clsx('text-box', {
						'text-box-active-enter-done': isFirstLoaded,
					})}
				>
					<div className='text-label'>Premium NFT Brand</div>
					<div className='description'>
						Step into the groove with Funky Llamas: 3,333 unique, trend-setting NFTs on Solana where retro charm meets
						digital innovation. Funky heard is the vibe.
					</div>

					<button
						ref={moreInfoRef}
						className={clsx('more-info-button more-info-button-active', {
							'more-info-button-active-loaded': isFirstLoaded,
						})}
					>
						<Link to='https://twitter.com/funkyllamasnft' target='_blank'>
							Coming Soon
							<ButtonArrImg className='button-arr-img' />
						</Link>
					</button>
				</div>
			</CSSTransition>
			<div
				className={clsx('player-section', {
					'player-section-active': showLogoButton,
					'player-section-active-loaded': isFirstLoaded,
				})}
			>
				<MusicPlayer />
			</div>
			<div
				className={clsx('footer-text', {
					'footer-text-active': showLogoButton,
					'footer-text-active-loaded': isFirstLoaded,
				})}
			>
				© 2024 FunkyLIamas
			</div>
		</>
	)
}
