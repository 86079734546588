import clsx from 'clsx'
import Accordion from 'react-bootstrap/Accordion'

const faqs = [
	{
		question: 'How many NFTs are in the Funky Llamas collection?',
		answer: 'The collection features 3,333 unique NFTs.',
	},
	{
		question: 'On which blockchain will Funky Llamas live?',
		answer: 'Funky Llamas is built for the Solana blockchain.',
	},
	{
		question: 'When will Funky Llamas be available for minting and what will be the price?',
		answer: 'The mint date and pricing are yet to be announced. Stay tuned for updates!',
	},
	{
		question: 'How can I acquire a Funky Llama NFT?',
		answer: 'You can mint Funky Llamas NFTs at launch or buy them on secondary markets afterwards.',
	},
]

type Props = {
	step9: boolean
}
export default function Faq({ step9 }: Props) {
	return (
		<div
			className={clsx('faq-box', {
				step9: step9,
			})}
		>
			<div className='faq-wrapper'>
				<Accordion>
					{faqs.map((faq, index) => (
						<Accordion.Item eventKey={index.toString()} key={index}>
							<Accordion.Header>{faq.question}</Accordion.Header>
							<Accordion.Body>{faq.answer}</Accordion.Body>
						</Accordion.Item>
					))}
				</Accordion>
			</div>
		</div>
	)
}
