import clsx from 'clsx'
import { useRive, Layout, Fit, Alignment, useStateMachineInput } from '@rive-app/react-canvas'
import toyRiv from 'assets/images/home/first-toy.riv'
import { useCallback, useState } from 'react'

const STATE_TOY_MACHINE_NAME = 'State Machine 1'
const ON_TOY_INPUT_NAME = 'on'
const OFF_TOY_INPUT_NAME = 'off'

export default function ToyRivComponent() {
	const [clickMiddleButton, setClickMiddleButton] = useState(false)

	const { rive, RiveComponent } = useRive({
		src: toyRiv,
		stateMachines: STATE_TOY_MACHINE_NAME,
		artboard: 'New Artboard',
		layout: new Layout({
			fit: Fit.FitHeight,
			alignment: Alignment.TopCenter,
		}),
		autoplay: true,
	})

	const pressedOnInputToy = useStateMachineInput(rive, STATE_TOY_MACHINE_NAME, ON_TOY_INPUT_NAME)
	const pressedOffInputToy = useStateMachineInput(rive, STATE_TOY_MACHINE_NAME, OFF_TOY_INPUT_NAME)

	const onStartToyAnimation = useCallback(() => {
		setClickMiddleButton(!clickMiddleButton)

		if (clickMiddleButton) {
			pressedOffInputToy?.fire()
			// setTimeout(() => {
			// 	pressedOnInput?.fire()
			// }, 1000)
		} else {
			pressedOnInputToy?.fire()
		}
	}, [pressedOffInputToy, pressedOnInputToy, clickMiddleButton])
	return (
		<div className={clsx('toy-icon-screen-box', {})}>
			<RiveComponent className='toy-icon-img' />
			<button className={clsx('first-screen-toy')} onClick={onStartToyAnimation}></button>
		</div>
	)
}
