import { useEffect, useState } from 'react'
import './index.scss'
import HomeComponent from 'components/Home'
import clsx from 'clsx'
import backgroundImage from 'assets/images/home/background.png'

const FIRST_LOAD_KEY = 'isFirstLoad'

export default function HomePage() {
	const [isFirstLoaded, setIsFirstLoaded] = useState(false)
	const [isLoad, setisLoad] = useState(false)
	const [imagesLoaded, setImageLoaded] = useState(false)

	useEffect(() => {
		const imagePath = backgroundImage
		const loadImage = () => {
			return new Promise<void>((resolve, reject) => {
				const img = new Image()
				img.src = imagePath
				img.onload = () => resolve()
				img.onerror = (error) => reject(error)
			})
		}
		const loadImageAndSetState = async () => {
			try {
				await loadImage()
				setImageLoaded(true)
			} catch (error) {
				console.error('Error loading image:', error as Error)
			}
		}
		loadImageAndSetState()
	}, [])

	useEffect(() => {
		const data = localStorage.getItem(FIRST_LOAD_KEY)
		const isFirsLoad = data && JSON.parse(data)
		if (isFirsLoad) {
			setIsFirstLoaded(true)
		} else {
			setTimeout(() => {
				setIsFirstLoaded(true)
				localStorage.setItem(FIRST_LOAD_KEY, JSON.stringify(true))
			}, 6000)
		}
		setisLoad(true)
	}, [])

	if (!isLoad || !imagesLoaded) {
		return null
	}

	return (
		<div
			className={clsx('home-page', {
				'main-background-visible': isFirstLoaded,
			})}
		>
			<HomeComponent isFirstLoaded={isFirstLoaded} />
		</div>
	)
}
