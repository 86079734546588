import { useRive, Layout, Fit, Alignment, useStateMachineInput } from '@rive-app/react-canvas'
import llamaRiv from 'assets/images/home/llamas-arm-ball.riv'
import { useEffect, useState } from 'react'

const STATE_MACHINE_NAME = 'State Machine 1'
const reaction_1 = 'reaction_1'
const reaction_2 = 'reaction_2'
const look_at_ball = 'look_at_ball'

export default function LlamasRivComponent({ isFirstLoaded }: { isFirstLoaded: boolean }) {
	const [isFirstAnim, setisFirstAnim] = useState(800)

	const { rive, RiveComponent } = useRive({
		src: llamaRiv,
		stateMachines: STATE_MACHINE_NAME,
		artboard: 'New Artboard',
		layout: new Layout({
			fit: Fit.FitHeight,
			alignment: Alignment.TopCenter,
		}),
		autoplay: true,
	})

	const reaction1 = useStateMachineInput(rive, STATE_MACHINE_NAME, reaction_1)
	const reaction2 = useStateMachineInput(rive, STATE_MACHINE_NAME, reaction_2)
	const reaction3 = useStateMachineInput(rive, STATE_MACHINE_NAME, look_at_ball)

	useEffect(() => {
		const arrayAnimation = [reaction1, reaction3, reaction2]
		let currentIndex = 0
		const intervalId = setInterval(() => {
			arrayAnimation[currentIndex]?.fire()
			setisFirstAnim(5000)
			currentIndex++
			if (currentIndex >= arrayAnimation.length) {
				currentIndex = 0
			}
		}, isFirstAnim)

		return () => clearInterval(intervalId)
	}, [reaction1, reaction2, reaction3, isFirstLoaded, isFirstAnim])

	return <RiveComponent className='lama-img' />
}
