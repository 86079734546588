import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from 'pages/HomePage'
import Home1Page from 'pages/Home1Page'
// import Home2Page from 'pages/Home2Page'

const Router = () => {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<HomePage />} />
					<Route path='/home1' element={<Home1Page />} />
					{/* <Route path='/home2' element={<Home2Page />} /> */}
				</Routes>
			</BrowserRouter>
		</>
	)
}

export default Router
