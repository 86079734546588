import './index.scss'
import FaceLlamaImg from 'assets/images/home/face_llama.png'
import { ReactComponent as PlayIcon } from 'assets/images/home/play-icon.svg'
import { ReactComponent as PauseIcon } from 'assets/images/home/pause-music.svg'
import { ReactComponent as ToggleIcon } from 'assets/images/home/toggle-sound-icon.svg'

import sound1 from 'assets/sounds/END OF AN ERA - The Kount & Kaelin Ellis.mp3'
import sound2 from 'assets/sounds/Woman Like You - Kryptogram.mp3'
import sound3 from 'assets/sounds/Sunshine Day - Freiboitar, Claus Casper.mp3'
import sound4 from 'assets/sounds/Uptown Funk - Bruno Mars Ft Mark Ronson.mp3'

import { useCallback, useEffect, useState } from 'react'
import { Howl } from 'howler'

const audioFiles = [sound1, sound2, sound3, sound4]

const dataSounds = [
	{
		artist: 'The Kount & Kaelin Ellis',
		soundName: 'END OF AN ERA',
		sound: sound1,
	},
	{
		artist: 'Kryptogram',
		soundName: 'Woman Like You',
		sound: sound2,
	},
	{
		artist: 'Freiboitar, Claus Casper',
		soundName: 'Sunshine Day',
		sound: sound3,
	},
	{
		artist: 'Bruno Mars Ft Mark Ronson',
		soundName: 'Uptown Funk',
		sound: sound4,
	},
]

export default function MusicPlayer() {
	const [playSound, setPlaySound] = useState(false)
	const [indexTrack, setIndexTrack] = useState(0)

	const toggleSound = useCallback(() => {
		setPlaySound(!playSound)
	}, [playSound])

	const nextTrack = useCallback(() => {
		if (indexTrack === audioFiles.length - 1) {
			return setIndexTrack(0)
		}
		setIndexTrack(indexTrack + 1)
	}, [indexTrack])

	const prevTrack = useCallback(() => {
		if (indexTrack === 0) {
			return setIndexTrack(audioFiles.length - 1)
		}
		setIndexTrack(indexTrack - 1)
	}, [indexTrack])

	useEffect(() => {
		const sound = new Howl({
			src: [audioFiles[indexTrack]],
			html5: true,
			volume: 0.5,
		})

		if (playSound) {
			sound.play()
		} else {
			sound.pause()
		}
		sound.on('end', () => {
			nextTrack()
		})

		return () => {
			sound.unload()
		}
	}, [indexTrack, nextTrack, playSound])

	return (
		<div className='music-player-box'>
			<img src={FaceLlamaImg} alt='' className='lamaface-img' />
			<div className='countent-box'>
				<div className='sound-box'>
					<div className='artist-name'>{dataSounds[indexTrack].artist}</div>
					<div className='sound-name'>{dataSounds[indexTrack].soundName}</div>
				</div>
				<div className='toggle-sound-box'>
					<ToggleIcon className='toggle-sound-icon' onClick={prevTrack} />
					<div className='play-icon' onClick={toggleSound}>
						{playSound ? <PauseIcon className='pause-icon' /> : <PlayIcon />}
					</div>
					<ToggleIcon className='toggle-sound-icon next-sound' onClick={nextTrack} />
				</div>
			</div>
		</div>
	)
}
