import { useRive, Layout, Fit, Alignment, useStateMachineInput } from '@rive-app/react-canvas'
import armBallRiv from 'assets/images/home/llamas-arm-ball.riv'
import { useEffect } from 'react'

const STATE_MACHINE_NAME = 'State Machine 1'
const hop = 'hop'

export default function ArmBallRivComponent() {
	const { rive, RiveComponent } = useRive({
		src: armBallRiv,
		stateMachines: STATE_MACHINE_NAME,
		artboard: 'hand',
		layout: new Layout({
			fit: Fit.FitHeight,
			alignment: Alignment.TopCenter,
		}),
		autoplay: true,
	})

	const reaction1 = useStateMachineInput(rive, STATE_MACHINE_NAME, hop)

	useEffect(() => {
		const intervalId = setInterval(() => {
			reaction1?.fire()
		}, 3000)

		return () => clearInterval(intervalId)
	}, [reaction1])

	return <RiveComponent className='hand-bal-img' />
}
