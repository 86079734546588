import { ReactComponent as DiscordIcon } from 'assets/images/svg/discord.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/svg/twitter.svg'

import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import 'swiper/css/mousewheel'
import { SwiperRef } from 'swiper/swiper-react'

import cat1 from 'assets/images/home1/collection/1.png'
import cat2 from 'assets/images/home1/collection/2.png'
import cat3 from 'assets/images/home1/collection/3.png'
import cat4 from 'assets/images/home1/collection/4.png'
import cat5 from 'assets/images/home1/collection/5.png'
import cat6 from 'assets/images/home1/collection/6.png'
import cat7 from 'assets/images/home1/collection/9.png'
import cat8 from 'assets/images/home1/collection/8.png'
import clsx from 'clsx'

import { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

const swiperOptionsDefaults = {
	className: 'mySwiper',
	spaceBetween: 20,
	slidesPerView: 4,
	centeredSlides: false,
	grabCursor: true,
	// loop: true,
	// autoplay: {
	// 	delay: 5_000,
	// 	disableOnInteraction: false,
	// 	stopOnLastSlide: false,
	// 	waitForTransition: true,
	// },
	// breakpoints: {
	// 	720: {
	// 		slidesPerView: 2,
	// 		spaceBetween: 20,
	// 		centeredSlides: false,
	// 	},
	// 	1150: {
	// 		slidesPerView: 3,
	// 		spaceBetween: 20,
	// 		centeredSlides: false,
	// 	},
	// 	1900: {
	// 		spaceBetween: 20,
	// 	},
	// },
	navigation: true,
	pagination: false,

	modules: [Autoplay, Pagination, Navigation],
}

const teamDataArray = [
	{
		img: cat1,
		name: 'Arcalion',
		role: 'Founder & CTO',
		twitter: '#',
		discord: '#',
	},
	{
		img: cat2,
		name: 'Noxira',
		role: 'COO',
		twitter: '#',
		discord: '#',
	},
	{
		img: cat3,
		name: 'GOTTO',
		role: 'Head of Marketing',
		twitter: '#',
		discord: '#',
	},
	{
		img: cat4,
		name: 'Yoko',
		role: 'Lead Artist',
		twitter: '#',
		discord: '#',
	},
	{
		img: cat5,
		name: 'Loggy',
		role: 'Community Lead',
		twitter: '#',
		discord: '#',
	},
	{
		img: cat6,
		name: 'Bammy',
		role: 'Games Lead',
		twitter: '#',
		discord: '#',
	},
	{
		img: cat7,
		name: 'STee',
		role: 'Events Lead',
		twitter: '#',
		discord: '#',
	},
	{
		img: cat8,
		name: 'Mango',
		role: 'Partnerships Lead',
		twitter: '#',
		discord: '#',
	},
]

type TeamProps = {
	step8: boolean
	step9: boolean
}

export default function Team({ step8, step9 }: TeamProps) {
	const swiperRef = useRef<SwiperRef | null>(null)

	const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true)
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
	const handleNext = useCallback(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext()
			setIsPrevButtonDisabled(swiperRef.current.swiper.isBeginning)
			setIsNextButtonDisabled(swiperRef.current.swiper.isEnd)
		}
	}, [swiperRef])

	const handlePrev = useCallback(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev()
			setIsPrevButtonDisabled(swiperRef.current.swiper.isBeginning)
			setIsNextButtonDisabled(swiperRef.current.swiper.isEnd)
		}
	}, [swiperRef])

	return (
		<div
			className={clsx('team-box', {
				step8: step8,
				step9: step9,
			})}
		>
			<div className='content-box-team'>
				<div className='team-title'>
					<span>MEET</span>
					THE TEAM
				</div>
				<div className='team-subtitle'>
					We are a team of visionary artists, blockchain enthusiasts, and creative mavericks. <br />
					Each member is dedicated to bringing you an NFT experience that&apos;s as authentic as it is innovative.
				</div>
				<div className='swiper-container'>
					<Swiper {...swiperOptionsDefaults} key={JSON.stringify(swiperOptionsDefaults)} ref={swiperRef}>
						{teamDataArray.map((item) => (
							<SwiperSlide key={item.name}>
								<div className='team-item'>
									<img src={item.img} alt='' />
									<div className='team-item-description-box'>
										<div className='team-item-name'>{item.name}</div>
										<div className='team-item-role'>{item.role}</div>
										<div className='team-item-social-box'>
											<Link to={''}>
												<TwitterIcon />
											</Link>
											<Link to={''}>
												<DiscordIcon />
											</Link>
										</div>
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
					<button
						onClick={handlePrev}
						className={clsx('swiper-button-prev-custom', {
							'swiper-button-disabled': isPrevButtonDisabled,
						})}
					></button>
					<button
						onClick={handleNext}
						className={clsx('swiper-button-next-custom', {
							'swiper-button-disabled': isNextButtonDisabled,
						})}
					></button>
				</div>
			</div>
		</div>
	)
}
