import './index.scss'
import Home1Component from 'components/Home1'

export default function Home1Page() {
	return (
		<div className='home1-page'>
			<Home1Component />
		</div>
	)
}
